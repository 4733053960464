import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Post, UrlParams } from '../types';
import { Record } from 'react-admin';
import dataProvider from '../providers/dataProvider';
import Layout from '../components/Layout';
import LoadingBox from '../components/LoadingBox';
import ErrorBox from '../components/ErrorBox';
import { Box, Grid, Paper, Typography } from '@mui/material';
import parse from 'html-react-parser';

const PostPage = () => {
    const { id } = useParams<UrlParams>();

    const [post, setPost] = useState<Post | Record>();

    const [loading, setLoading] = useState<boolean>();
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider
            .getOne('posts', { id })
            .then(({ data }) => {
                setPost(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [id]);

    if (loading)
        return (
            <Layout>
                <LoadingBox />
            </Layout>
        );

    if (error)
        return (
            <Layout>
                <ErrorBox message={error} />
            </Layout>
        );

    return (
        <Layout>
            <Grid container justifyContent="center">
                <Box py={2}>
                    <Typography variant="h2">{post?.title}</Typography>
                </Box>
            </Grid>
            {post?.content && (
                <Paper>
                    <Box px={2} py={1}>
                        <Typography variant="body2">{parse(post?.content)}</Typography>
                    </Box>
                </Paper>
            )}
        </Layout>
    );
};

export default PostPage;
