import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { Record } from 'react-admin';
import { Member } from '../types';
import dataProvider from '../providers/dataProvider';
import MemberCard from '../components/MemberCard';
import ErrorBox from '../components/ErrorBox';
import LoadingBox from '../components/LoadingBox';
import { Box, Grid } from '@mui/material';

const MembersPage = () => {
    const [members, setMembers] = useState<Array<Member | Record>>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider
            .getList('members', {
                filter: { year: 2021 },
                pagination: { perPage: 100, page: 1 },
                sort: { field: 'year', order: 'DESC' },
            })
            .then(({ data }) => {
                setMembers(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading)
        return (
            <Layout>
                <LoadingBox />
            </Layout>
        );

    if (error)
        return (
            <Layout>
                <ErrorBox message={error} />
            </Layout>
        );

    return (
        <Layout>
            <Box py={2}>
                <Grid container spacing={2}>
                    {members && members.length > 0 ? (
                        members
                            .sort((a, b) => a.order - b.order)
                            .map((member) => {
                                return (
                                    <Grid item xs={12} md={4} xl={3} key={member.id}>
                                        <MemberCard member={member} />
                                    </Grid>
                                );
                            })
                    ) : (
                        <p>No members! :(</p>
                    )}
                </Grid>
            </Box>
        </Layout>
    );
};

export default MembersPage;
