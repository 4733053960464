import axios, { AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import {
    GetOneParams,
    DeleteParams,
    CreateParams,
    GetListParams,
    UpdateParams,
    UpdateManyParams,
    DeleteManyParams,
    GetManyReferenceParams,
    GetListResult,
    GetOneResult,
    GetManyResult,
    GetManyReferenceResult,
    UpdateResult,
    UpdateManyResult,
    CreateResult,
    DeleteResult,
    DeleteManyResult,
    Record,
} from 'react-admin';

const apiUrl = '/api';

const getList = async <RecordType extends Record = Record>(
    resource: string,
    params: GetListParams,
): Promise<GetListResult<RecordType>> => {
    const { page, perPage } = params.pagination;
    const query = {
        skip: JSON.stringify((page - 1) * perPage),
        limit: JSON.stringify(perPage),
        filter: JSON.stringify(params.filter),
        order: params.sort
            ? JSON.stringify([`${params.sort.field}__${params.sort.order === 'DESC' ? 'd' : 'a'}`])
            : null,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    const token: string | null = localStorage.getItem('token');
    const config = {
        headers: { Authorization: token },
    };
    const res: AxiosResponse = await axios.get(url, config);
    return {
        data: res.data,
        total: parseInt(res.headers['x-total-count']),
    };
};

const getOne = async <RecordType extends Record = Record>(
    resource: string,
    params: GetOneParams,
): Promise<GetOneResult<RecordType>> => {
    const token: string | null = localStorage.getItem('token');
    const config = {
        headers: { Authorization: token },
    };
    const res: AxiosResponse = await axios.get(`${apiUrl}/${resource}/${params.id}`, config);
    return {
        data: res.data,
    };
};

const getOnePublic = async <RecordType extends Record = Record>(
    resource: string,
    params: GetOneParams,
): Promise<GetOneResult<RecordType>> => {
    const token: string | null = localStorage.getItem('token');
    const config = {
        headers: { Authorization: token },
    };
    const res: AxiosResponse = await axios.get(`${apiUrl}/${resource}/${params.id}/public`, config);
    return {
        data: res.data,
    };
};

const getMany = async <RecordType extends Record = Record>(resource: string): Promise<GetManyResult<RecordType>> => {
    const token: string | null = localStorage.getItem('token');
    const config = {
        headers: { Authorization: token },
    };
    const res: AxiosResponse = await axios.get(`${apiUrl}/${resource}`, config);
    return {
        data: res.data,
    };
};

const getManyReference = async <RecordType extends Record = Record>(
    resource: string,
    params: GetManyReferenceParams,
): Promise<GetManyReferenceResult<RecordType>> => {
    return getList(resource, params);
};

const update = async <RecordType extends Record = Record>(
    resource: string,
    params: UpdateParams,
): Promise<UpdateResult<RecordType>> => {
    const token = localStorage.getItem('token');
    const config = {
        headers: { Authorization: token },
    };

    // Upload file in appropriate resource and image exists
    if ((resource === 'partners' || resource === 'members') && params.data.image) {
        const uploadUrl = `${apiUrl}/${resource}/${params.id}/upload`;
        const uploadConfig = {
            headers: { Authorization: token, 'content-type': 'multipart/form-data' },
        };
        const formData = new FormData();
        formData.append('in_file', params.data.image.rawFile);

        // Delete from data so it won't be sent again
        delete params.data.image;

        await axios.post(uploadUrl, formData, uploadConfig);
    }

    const url = `${apiUrl}/${resource}/${params.id}`;
    const res: AxiosResponse = await axios.put(url, params.data, config);
    return {
        data: res.data,
    };
};

const updateMany = async (resource: string, params: UpdateManyParams): Promise<UpdateManyResult> => {
    return {
        data: [],
    };
};

const create = async <RecordType extends Record = Record>(
    resource: string,
    params: CreateParams,
): Promise<CreateResult<RecordType>> => {
    const token = localStorage.getItem('token');
    const config = {
        headers: { Authorization: token },
    };
    const url = `${apiUrl}/${resource}`;
    const res: AxiosResponse = await axios.post(url, params.data, config);
    return {
        data: { ...params.data, id: res.data.id },
    };
};

const remove = async <RecordType extends Record = Record>(
    resource: string,
    params: DeleteParams,
): Promise<DeleteResult<RecordType>> => {
    const token = localStorage.getItem('token');
    const config = {
        headers: { Authorization: token },
    };
    const res: AxiosResponse = await axios.delete(`${apiUrl}/${resource}/${params.id}`, config);
    return {
        data: res.data,
    };
};

const deleteMany = async (resource: string, params: DeleteManyParams): Promise<DeleteManyResult> => {
    return {
        data: [],
    };
};

export default {
    getList,
    getOne,
    getMany,
    getManyReference,
    create,
    update,
    updateMany,
    delete: remove,
    deleteMany,
    getOnePublic,
};
