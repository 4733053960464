import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Footer from './Footer';

interface LayoutProps {
    children: ReactElement | Element | ReactElement[] | Element[];
}

const Layout = ({ children }: LayoutProps) => {
    return (
        <div>
            <AppBar position="static">
                <Toolbar disableGutters>
                    <Box px={2}>
                        <Typography
                            variant="h6"
                            noWrap
                            component={NavLink}
                            to="/"
                            style={{ textDecoration: 'none', boxShadow: 'none', color: '#ffffff' }}
                            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                        >
                            Hewo
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <main>
                <Container>{children}</Container>
            </main>
            <footer>
                <Footer />
            </footer>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
