import React, { useEffect, useState } from 'react';
import { Post } from '../../types';
import { Record } from 'react-admin';
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PostCardSubHeader from './PostCardSubHeader';

interface PostCardProps {
    post: Post | Record;
}

const PostCard = ({ post }: PostCardProps) => {
    const regex = /(<([^>]+)>)/gi;
    const [content, setContent] = useState<string>('');
    const [preview, setPreview] = useState<string>('');

    useEffect(() => {
        setContent(post.content.replace(regex, ''));
    }, [post]);

    useEffect(() => {
        if (content.length > 500) {
            setPreview(content.substring(0, 500) + '...');
        } else {
            setPreview(content);
        }
    }, [content]);

    return (
        <>
            <Card elevation={2}>
                <CardHeader
                    title={post.title}
                    subheader={
                        <PostCardSubHeader
                            author={post.author}
                            created_on={post.created_on}
                            updated_on={post.updated_on}
                        />
                    }
                />
                <CardContent>
                    <Typography variant="body2">{preview}</Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" component={Link} to={`/posts/${post.id}`}>
                        Lue lisää
                    </Button>
                </CardActions>
            </Card>
        </>
    );
};

export default PostCard;
