import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import AdminPage from './views/AdminPage';
import NotFoundPage from './views/NotFoundPage';
import MembersPage from './views/MembersPage';
import HomePage from './views/HomePage';
import { CssBaseline } from '@mui/material';
import { theme } from './theme';
import PostPage from './views/PostPage';
import AuthorPage from './views/AuthorPage';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Switch>
                    <Route exact path="/">
                        <HomePage />
                    </Route>
                    <Route exact path="/members">
                        <MembersPage />
                    </Route>
                    <Route exact path="/members/:year">
                        <MembersPage />
                    </Route>
                    <Route path="/admin">
                        <AdminPage />
                    </Route>
                    <Route exact path="/posts">
                        <p>Posts</p>
                    </Route>
                    <Route path="/posts/:id">
                        <PostPage />
                    </Route>
                    <Route path="/authors/:id">
                        <AuthorPage />
                    </Route>
                    <Route path="*">
                        <NotFoundPage />
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>
    );
}

export default App;
