import React from 'react';

import {
    List,
    Create,
    Edit,
    Show,
    Datagrid,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    DateField,
    TextInput,
    PasswordInput,
    SelectInput,
    SelectField,
    EmailField,
    ReferenceManyField,
    ShowButton,
} from 'react-admin';

interface ModificationProps {
    permissions?: string;
}

const roles = [
    { id: 'admin', name: 'admin' },
    { id: 'member', name: 'member' },
    { id: 'writer', name: 'writer' },
];

const UserList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="show" isRowSelectable={() => false}>
                <TextField source="username" label="Username" />
                <EmailField source="email" label="Email" />
                <SelectField source="role" label="Role" choices={roles} />
                <DateField source="created_on" showTime />
                <DateField source="updated_on" showTime />
            </Datagrid>
        </List>
    );
};

const UserCreate = ({ permissions, ...props }: ModificationProps) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="username" label="Username" />
                {permissions === 'admin' && (
                    <div>
                        <TextInput source="email" label="Email" type="email" />
                        <PasswordInput source="password" label="Password" />
                        <SelectInput source="role" label="Role" choices={roles} />
                    </div>
                )}
                <DateField source="created_on" showTime />
                <DateField source="updated_on" showTime />
            </SimpleForm>
        </Create>
    );
};

const UserEdit = ({ permissions, ...props }: ModificationProps) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="username" label="Username" />
                {permissions === 'admin' && (
                    <div>
                        <TextInput source="email" label="Email" />
                        <PasswordInput source="password" label="Password" />
                        <SelectInput source="role" label="Role" choices={roles} />
                    </div>
                )}
                <DateField source="created_on" showTime />
                <DateField source="updated_on" showTime />
            </SimpleForm>
        </Edit>
    );
};

const UserShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="username" label="Username" />
                <EmailField source="email" label="Email" />
                <SelectField source="role" label="Role" choices={roles} />
                <ReferenceManyField reference="posts" target="author_id" label="Posts">
                    <Datagrid>
                        <DateField source="created_on" showTime />
                        <TextField source="title" />
                        <ShowButton />
                    </Datagrid>
                </ReferenceManyField>
                <DateField source="created_on" showTime />
                <DateField source="updated_on" showTime />
            </SimpleShowLayout>
        </Show>
    );
};

export { UserList, UserCreate, UserEdit, UserShow };
