import React from 'react';

import {
    List,
    Create,
    Edit,
    Show,
    Datagrid,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    DateField,
    TextInput,
    ReferenceField,
    RichTextField,
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';

interface ModificationProps {
    permissions?: string;
}

const PostList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="show" isRowSelectable={() => false}>
                <TextField source="title" label="Title" />
                <ReferenceField label="User" source="author_id" reference="users">
                    <TextField source="username" />
                </ReferenceField>
                <DateField source="created_on" showTime />
                <DateField source="updated_on" showTime />
            </Datagrid>
        </List>
    );
};

const PostCreate = ({ permissions, ...props }: ModificationProps) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="title" label="Title" />
                <RichTextInput source="content" />
                <DateField source="created_on" showTime />
                <DateField source="updated_on" showTime />
            </SimpleForm>
        </Create>
    );
};

const PostEdit = ({ permissions, ...props }: ModificationProps) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="title" label="Title" />
                <RichTextInput source="content" />
                <DateField source="created_on" showTime />
                <DateField source="updated_on" showTime />
            </SimpleForm>
        </Edit>
    );
};

const PostShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="title" label="Title" />
                <ReferenceField label="User" source="author_id" reference="users">
                    <TextField source="username" />
                </ReferenceField>
                <RichTextField source="content" />
                <DateField source="created_on" showTime />
                <DateField source="updated_on" showTime />
            </SimpleShowLayout>
        </Show>
    );
};

export { PostList, PostCreate, PostEdit, PostShow };
