import React from 'react';
import { Record } from 'react-admin';
import { User } from '../../types';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { fi } from 'date-fns/locale';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface PostCardSubHeaderProps {
    author: User | Record;
    created_on: string;
    updated_on: string;
}

const PostCardSubHeader = ({ author, created_on, updated_on }: PostCardSubHeaderProps) => {
    return (
        <>
            <Link component={RouterLink} to={`/authors/${author.id}`}>{`${author.username}`}</Link>
            {updated_on ? (
                <>
                    {` - ${format(utcToZonedTime(created_on + 'Z', 'Europe/Helsinki'), 'Pp', {
                        locale: fi,
                    })}
                    - Muokattu: ${format(utcToZonedTime(updated_on + 'Z', 'Europe/Helsinki'), 'Pp', {
                        locale: fi,
                    })}
                    `}
                </>
            ) : (
                <>
                    {` - ${format(utcToZonedTime(created_on + 'Z', 'Europe/Helsinki'), 'Pp', {
                        locale: fi,
                    })}
                    `}
                </>
            )}
        </>
    );
};

export default PostCardSubHeader;
