import React, { ReactElement } from 'react';
import { IconButton, Link } from '@mui/material';

export interface FooterSocial {
    icon: ReactElement;
    link: string;
    name: string;
}

export interface FooterSocialButtonProps {
    icon: ReactElement;
    link: string;
    name: string;
}

const FooterSocialButton = ({ icon, link, name }: FooterSocialButtonProps) => {
    return (
        <IconButton aria-label={`${name}`} component={Link} href={`${link}`}>
            {icon}
        </IconButton>
    );
};

export default FooterSocialButton;
