import React, { useState } from 'react';
import { Member } from '../types';
import { Record } from 'react-admin';
import { Card, CardContent, CardHeader } from '@mui/material';

interface MemberProps {
    member: Member | Record;
}

const MemberCard = ({ member }: MemberProps) => {
    const [hasImage, setHasImage] = useState(true);

    return (
        <>
            <Card elevation={2}>
                <CardHeader title={member.name} subheader={`${member.title} - ${member.year}`} />
                <CardContent>
                    {hasImage ? (
                        <img
                            style={{ width: '100%', height: 'auto', borderRadius: '1%' }}
                            src={`/api/members/${member.id}/img`}
                            onError={() => setHasImage(false)}
                            alt={member.name}
                        />
                    ) : null}
                </CardContent>
            </Card>
        </>
    );
};

export default MemberCard;
