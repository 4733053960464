import React, { useEffect, useState } from 'react';
import { Post, UrlParams, User } from '../types';
import { Record } from 'react-admin';
import { useParams } from 'react-router-dom';
import dataProvider from '../providers/dataProvider';
import { Box, Grid, Pagination, Typography } from '@mui/material';
import Layout from '../components/Layout';
import ErrorBox from '../components/ErrorBox';
import LoadingBox from '../components/LoadingBox';
import PostCard from '../components/posts/PostCard';

const AuthorPage = () => {
    const { id } = useParams<UrlParams>();

    const [posts, setPosts] = useState<Array<Post | Record>>([]);
    const [author, setAuthor] = useState<User | Record>();
    const [pages, setPages] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage] = useState<number>(5);

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider
            .getOnePublic('users', { id })
            .then(({ data }) => {
                setAuthor(data);
            })
            .catch((error) => {
                setError(error);
            });
    }, [id]);

    useEffect(() => {
        setLoading(true);
        dataProvider
            .getList('posts', {
                filter: { author: { id } },
                pagination: { perPage: perPage, page: currentPage },
                sort: { field: 'created_on', order: 'DESC' },
            })
            .then(({ data, total }) => {
                setPosts(data);
                setPages(Math.ceil(total / perPage));

                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [id, currentPage]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    if (loading)
        return (
            <Layout>
                <LoadingBox />
            </Layout>
        );

    if (error)
        return (
            <Layout>
                <ErrorBox message={error} />
            </Layout>
        );

    return (
        <Layout>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box py={2}>
                        <Typography variant="h3" align="center">
                            Käyttäjältä: {author && author.username}
                        </Typography>
                    </Box>
                </Grid>
                {posts && posts.length > 0 ? (
                    posts.map((post) => {
                        return (
                            <Grid item xs={12} key={post.id}>
                                <PostCard post={post} />
                            </Grid>
                        );
                    })
                ) : (
                    <p>TODO no posts</p>
                )}
                <Grid item container xs={12} justifyContent="center">
                    <Grid item xs={'auto'}>
                        <Pagination
                            count={pages}
                            page={currentPage}
                            onChange={handlePageChange}
                            disabled={pages === 1}
                            showFirstButton
                            showLastButton
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default AuthorPage;
