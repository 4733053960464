import React from 'react';
import { Admin, Resource, Login } from 'react-admin';
import { Route } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import authProvider from '../providers/authProvider';
import dataProvider from '../providers/dataProvider';

import { UserList, UserCreate, UserShow, UserEdit } from '../components/admin/users';
import { MemberCreate, MemberList, MemberShow, MemberEdit } from '../components/admin/members';
import { PartnerList, PartnerCreate, PartnerEdit, PartnerShow } from '../components/admin/partners';
import { PostList, PostCreate, PostEdit, PostShow } from '../components/admin/posts';
import { raThemeOptions } from '../theme';

import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import StoreIcon from '@mui/icons-material/Store';
import ArticleIcon from '@mui/icons-material/Article';

const history = createHistory({ basename: '/admin' });
const customRoutes = [<Route exact path="/admin/login" component={Login} key={0} />];

const AdminPage = () => {
    return (
        <Admin
            theme={raThemeOptions}
            customRoutes={customRoutes}
            history={history}
            authProvider={authProvider}
            dataProvider={dataProvider}
        >
            {(permissions) => [
                permissions === 'admin' ? (
                    <Resource
                        name="users"
                        list={UserList}
                        create={UserCreate}
                        show={UserShow}
                        edit={UserEdit}
                        options={{ label: 'Users' }}
                        icon={PersonIcon}
                        key={1}
                    />
                ) : null,
                permissions === 'admin' || permissions === 'writer' ? (
                    <Resource
                        name="members"
                        list={MemberList}
                        create={MemberCreate}
                        show={MemberShow}
                        edit={MemberEdit}
                        options={{ label: 'Members' }}
                        icon={BadgeIcon}
                        key={2}
                    />
                ) : null,
                permissions === 'admin' || permissions === 'writer' ? (
                    <Resource
                        name="partners"
                        list={PartnerList}
                        create={PartnerCreate}
                        show={PartnerShow}
                        edit={PartnerEdit}
                        options={{ label: 'Partners' }}
                        icon={StoreIcon}
                        key={3}
                    />
                ) : null,
                permissions === 'admin' || permissions === 'writer' ? (
                    <Resource
                        name="posts"
                        list={PostList}
                        create={PostCreate}
                        show={PostShow}
                        edit={PostEdit}
                        options={{ label: 'Posts' }}
                        icon={ArticleIcon}
                        key={4}
                    />
                ) : null,
            ]}
        </Admin>
    );
};

export default AdminPage;
