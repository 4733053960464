import React from 'react';
import Layout from '../components/Layout';
import { Box, Grid, Typography } from '@mui/material';

const NotFoundPage = () => {
    return (
        <Layout>
            <Box p={10} />
            <Grid container justifyContent="center">
                <Grid item>
                    <Typography variant="h1" align="center">
                        404
                    </Typography>
                    <Typography variant="h3" align="center">
                        Sivua ei löytynyt :/
                    </Typography>
                </Grid>
            </Grid>
            <Box p={10} />
        </Layout>
    );
};

export default NotFoundPage;
