import React from 'react';
import { Box, Container, Grid, Paper } from '@mui/material';
import { ReactComponent as HewoLogo } from '../images/hewo_logo.svg';
import { styled } from '@mui/material/styles';
import NewsBox from '../components/home/NewsBox';
import Footer from '../components/Footer';

const LogoPaper = styled(Paper)(
    ({ theme }) => `
    background-color: ${theme.palette.background.default};
    border-radius: 50%;
    line-height: 0;
    padding: 5px;
    `,
);

const HomePage = () => {
    return (
        <>
            <main>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} container spacing={2} justifyContent="center">
                            <Grid item xl={3} xs={6}>
                                <LogoPaper elevation={0}>
                                    <HewoLogo />
                                </LogoPaper>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box pb={1}>
                                <Paper>
                                    <Box p={2}>
                                        <Grid container spacing={0} justifyContent="center">
                                            <Grid item xl={6} xs={12}>
                                                <NewsBox amount={5} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </main>
            <footer>
                <Footer />
            </footer>
        </>
    );
};

export default HomePage;
