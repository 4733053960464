import React, { ReactElement, useEffect, useState } from 'react';
import { ReactComponent as HewoLogo } from '../images/hewo_logo.svg';
import { Box, Grid, Link, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import FooterSocialButton, { FooterSocial } from './footer/FooterSocialButton';
import { Chat, GitHub } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

const FooterLogo = styled(HewoLogo)`
    width: 75px;
    height: auto;
`;

const Footer = () => {
    const [socials, setSocials] = useState<Array<FooterSocial>>([]);
    useEffect(() => {
        setSocials([
            {
                icon: <GitHub />,
                link: 'https://github.com/hewo-ry',
                name: 'Github',
            },
            {
                icon: <Chat />,
                link: 'https://discord.com/invite/hBY5btQ',
                name: 'Discord',
            },
        ]);
    }, []);
    return (
        <Paper elevation={0}>
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item container xs={3}>
                        <Grid item container xs={12} spacing={1}>
                            <Grid item xs={3}>
                                <FooterLogo />
                            </Grid>
                            <Grid item container xs direction="column" justifyContent="center" alignItems="center">
                                <Grid item xs={'auto'}>
                                    <Typography variant="h6">Herwannan Vapaaohjelmoijat Ry</Typography>
                                </Grid>
                                <Grid item container xs justifyContent="center">
                                    {socials &&
                                        socials.map((social) => {
                                            return (
                                                <Grid item key={social.name}>
                                                    <FooterSocialButton
                                                        icon={social.icon}
                                                        link={social.link}
                                                        name={social.name}
                                                    />
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs container>
                        <Grid item xs={12}>
                            <Typography variant="h6">Linkkejä</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Link component={RouterLink} to="/">
                                Koti
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Link component={RouterLink} to="/info">
                                Tietoa
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Link component={RouterLink} to="/members">
                                Hallitus
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Link component={RouterLink} to="/posts">
                                Uutiset
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h6">Hyödyllistä</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h6">Yhteystiedot</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box p={5} />
        </Paper>
    );
};

export default Footer;
