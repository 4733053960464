import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

interface ErrorBoxProps {
    message: string;
}

const ErrorBox = ({ message }: ErrorBoxProps) => {
    return (
        <Grid container justifyContent="center">
            <Grid item>
                <Paper>
                    <Typography>Error: {message}</Typography>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ErrorBox;
