import React from 'react';

import {
    List,
    Create,
    Edit,
    Show,
    Datagrid,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    DateField,
    TextInput,
    NumberField,
    NumberInput,
    ImageInput,
    ImageField,
} from 'react-admin';

interface ModificationProps {
    permissions?: string;
}

const MemberList = (props: any) => {
    return (
        <List {...props}>
            <Datagrid rowClick="show" isRowSelectable={() => false}>
                <TextField source="name" label="Name" />
                <TextField source="title" label="Title" />
                <NumberField source="order" label="Order" />
                <NumberField source="year" label="Year" />
                <DateField source="created_on" showTime />
                <DateField source="updated_on" showTime />
            </Datagrid>
        </List>
    );
};

const MemberCreate = ({ permissions, ...props }: ModificationProps) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" label="Name" />
                <TextInput source="title" label="Title" />
                <NumberInput source="order" label="Order" step={1} />
                <NumberInput source="year" label="Year" min={2000} max={4000} step={1} />
                <DateField source="created_on" showTime />
                <DateField source="updated_on" showTime />
            </SimpleForm>
        </Create>
    );
};

const MemberEdit = ({ permissions, ...props }: ModificationProps) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="name" label="Name" />
                <TextInput source="title" label="Title" />
                <NumberInput source="order" label="Order" step={1} />
                <NumberInput source="year" label="Year" min={2000} max={4000} step={1} />
                <ImageInput source="image" label="Image">
                    <ImageField source="src" />
                </ImageInput>
                <DateField source="created_on" showTime />
                <DateField source="updated_on" showTime />
            </SimpleForm>
        </Edit>
    );
};

const MemberShow = (props: any) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="name" label="Name" />
                <TextField source="title" label="Title" />
                <NumberField source="order" label="Order" />
                <NumberField source="year" label="Year" />
                <div>
                    <img
                        style={{ width: '30%', height: 'auto' }}
                        src={`/api/members/${props.id}/img`}
                        title={props.id}
                        alt={props.id}
                    />
                </div>
                <DateField source="created_on" showTime />
                <DateField source="updated_on" showTime />
            </SimpleShowLayout>
        </Show>
    );
};

export { MemberList, MemberCreate, MemberEdit, MemberShow };
