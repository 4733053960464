import axios, { AxiosResponse } from 'axios';
import decodeJwt from 'jwt-decode';

const baseUrl = '/api';

interface Login {
    username: string;
    password: string;
}

interface TokenBody {
    uuid: string;
    username: string;
    role: string;
}

const login = async ({ username, password }: Login): Promise<void> => {
    const formData: FormData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    try {
        const res: AxiosResponse = await axios.post(`${baseUrl}/auth/token`, formData);
        const token: string = res.data.access_token;
        const decodedToken: TokenBody = decodeJwt(token);

        localStorage.setItem('token', `Bearer ${token}`);
        localStorage.setItem('permissions', decodedToken.role);
    } catch (error) {
        return Promise.reject();
    }
};

const logout = (): Promise<void> => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');

    return Promise.resolve();
};

const checkError = (status: { message: string }): Promise<void> => {
    const message: string = status.message;
    if (message === 'Request failed with status code 401') {
        localStorage.removeItem('token');
        return Promise.reject();
    }
    return Promise.resolve();
};

const checkAuth = (): Promise<void> => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
};

const getPermissions = (): Promise<string> => {
    const role = localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject();
};

export default {
    login,
    logout,
    checkError,
    checkAuth,
    getPermissions,
};
