import React, { useEffect, useState } from 'react';
import { Record } from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { Post } from '../../types';
import dataProvider from '../../providers/dataProvider';
import PostCard from '../posts/PostCard';

interface NewsBoxProps {
    amount: number;
}

const NewsBox = ({ amount }: NewsBoxProps) => {
    const [posts, setPosts] = useState<Array<Post | Record>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider
            .getList('posts', {
                filter: {},
                pagination: { perPage: amount, page: 1 },
                sort: { field: 'created_on', order: 'DESC' },
            })
            .then(({ data }) => {
                setPosts(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [amount]);

    if (loading) return <p>TODO loading</p>;

    if (error) return <p>TODO error {error}</p>;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" align="center">
                        Uutisia
                    </Typography>
                </Grid>
                {posts && posts.length > 0 ? (
                    posts.map((post) => {
                        return (
                            <Grid item xs={12} key={post.id}>
                                <PostCard post={post} />
                            </Grid>
                        );
                    })
                ) : (
                    <p>TODO no posts</p>
                )}
            </Grid>
        </>
    );
};

export default NewsBox;
